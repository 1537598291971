import React, { useRef, useState } from 'react';
import { useMount } from 'ahooks';
import { useForm } from 'react-hook-form';
import { MenuItem } from "@material-ui/core"
import { Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import '../shared/stepForm.scss';
import './index.scss'
import {
  BackButton, BreadcrumbsClass, ButtonContainer,
  CenterNextButton, EmptyDiv, FormContainerClass, LabelClass,
  NextButton, OnboardingStepContainerClass, TitleClass
} from "../shared/BootStrapStyling";
import { isEmpty } from 'lodash';
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import RadioButton from '../StepThree/RadioButton';
import SingleSelect from "../shared/SingleSelect";

const StepFour = ({ industries, onSubmit, defaultValue, onBack }) => {
  const { handleSubmit } = useForm({ mode: 'onBlur' })

  const IsSubmitted = useRef(false);

  const [businessesAcquired, setBusinessesAcquired] = useState(null)
  const [businessesSold, setBusinessesSold] = useState(null)
  const [industriesOptions, setIndustriesOptions] = useState([])
  const [selectedAcquiredIndustry, setSelectedAcquiredIndustry] = useState('')
  const [selectedSaleIndustry, setSelectedSaleIndustry] = useState('')

  useMount(() => {
    setIndustriesOptions(industries ?? [])
    setBusinessesAcquired(defaultValue?.businesses_acquired_code ?? null)
    setBusinessesSold(defaultValue?.businesses_sold_code ?? null)
    setSelectedAcquiredIndustry(defaultValue?.last_acquisition_industry_id ?? '')
    setSelectedSaleIndustry(defaultValue?.last_sale_industry_id ?? '')
  });

  const onSubmitStepFour = () => {
    const updatedValues = {
      ...defaultValue,
      businesses_acquired_code: businessesAcquired,
      businesses_sold_code: businessesSold,
      last_acquisition_industry_id: selectedAcquiredIndustry ? selectedAcquiredIndustry : null,
      last_sale_industry_id: selectedSaleIndustry ? selectedSaleIndustry : null,
    }
    if (IsSubmitted.current) {
      onSubmit(updatedValues);
    } else {
      onBack(updatedValues)
    }
  };
  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={2} /></div>
      <form
        id="stepFour"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepFour)}
        className={`${FormContainerClass}`}
      >
        <div>
          <h1 className={`${TitleClass}`}>
            Acquisition Experience
          </h1>
        </div>
        <div className='w-100 pt-3'>
          <div>
            <label className={`${LabelClass}`}>How many businesses have you acquired?</label>
            <div className='radio-section px-1' onChange={e => setBusinessesAcquired(Number(e.target.value))}>
              <RadioButton value={0} label='Zero' state={businessesAcquired} group={'businesses-acquired'} />
              <RadioButton value={1} label='1-2' state={businessesAcquired} group={'businesses-acquired'} />
              <RadioButton value={2} label='2+' state={businessesAcquired} group={'businesses-acquired'} />
            </div>
          </div>
          <div className='pb-4 w-100'>
            <label className={`${LabelClass}`}>Industry of last acquisition</label>
            <SingleSelect
              placeholder='Select Industry'
              disabled={isEmpty(industriesOptions) || !businessesAcquired}
              selectedValue={selectedAcquiredIndustry}
              setSelectedValue={setSelectedAcquiredIndustry}
            >
              {industriesOptions?.map(industry => (
                <MenuItem value={industry.id} key={industry.name + industry.id}>
                  {industry.name}
                </MenuItem>
              ))}
            </SingleSelect>
          </div>
        </div>
        <div>
          <div>
            <label className={`${LabelClass}`}>How many businesses have you sold?</label>
            <div className='radio-section px-1' onChange={e => setBusinessesSold(Number(e.target.value))}>
              <RadioButton value={0} label='Zero' state={businessesSold} group={'businesses-sold'} />
              <RadioButton value={1} label='1-2' state={businessesSold} group={'businesses-sold'} />
              <RadioButton value={2} label='2+' state={businessesSold} group={'businesses-sold'} />
            </div>
          </div>
          <div className='pb-4'>
            <label className={`${LabelClass}`}>Industry of last sale</label>
            <SingleSelect
              placeholder='Select Industry'
              disabled={isEmpty(industriesOptions) || !businessesSold}
              selectedValue={selectedSaleIndustry}
              setSelectedValue={setSelectedSaleIndustry}
            >
              {industriesOptions?.map(industry => (
                <MenuItem value={industry.id} key={industry.name + industry.id}>
                  {industry.name}
                </MenuItem>
              ))}
            </SingleSelect>
          </div>
        </div>
        <div className={`${ButtonContainer}`}>
          <div>
            <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                    className={`${BackButton}`}>
              {'Previous'}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button type='submit' onClick={onClickSubmit}  className={`${NextButton}`}>
              {'Next'}
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
        </div>
      </form>
    </div>
  );
};

StepFour.defaultProps = {};

StepFour.propTypes = {};

export default StepFour;
