import React from 'react'

const RadioButton = ({ label, group = 'default' + label, value, state, onChange, valueType, ...rest }) => {

  function handleChange(e) {
    if(onChange) {
      switch(valueType) {
        case 'number':
          return onChange(Number(e.target.value))
        case 'text':
          return onChange(e.target.value)
      }
    }
  }

  return (
    <div className='radio-wrapper'>
      <input {...rest} name={group} type='radio' value={value} checked={state === value} onChange={handleChange}/>
      <h1 className='radio-label font-regular'>{label}</h1>
    </div>
  )
}

export default RadioButton
