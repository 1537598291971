import "./Header.scss";

import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";

import {
  Avatar,
  Button,
  Fade,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  useMediaQuery,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

import AuthHelper from "shared/helpers/AuthHelper";
import vwLogo from "assets/images/vw-header-logo-new.PNG";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles({
  container: {
    height: 95,
    position: "fixed",
    zIndex: 2,
    backgroundColor: "#fff",
  },
  containerMediaQuary: {
    height: 95,

    position: "relative",
    marginBottom: "-130px !important",
  },
  containerMediaQuaryIsopen: {
    height: "auto",
    position: "relative",
    marginBottom: "-60px !important",
  },
  topnav: {
    overflow: "hidden",
    backgroundColor: "#fff",
    position: "relative",
  },
  topnavOpen: {
    background: "#324f61",
  },
  topNavBarLink: {
    float: "none",
    display: "block",
    textAlign: "left",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f5f5f5",
      "&a.MuiLink-root": {
        color: "#558BB9 !important",
      },
    },
  },
  menuOpen: {
    background: "#324f61",
    marginRight: "-1rem",
    padding: "4px",
    position: "absolute",
    right: "20px",
    top: "2px",
  },
  button: {
    color: "#324F61 !important",
    borderRadius: "4px !important",
    "&.MuiButton-outlined": {
      border: "2px solid #324F61",
    },
    fontFamily: "Spartan-Bold !important",
    fontSize: "16px !important",
    padding: "2px",
    width: "auto !important",
  },
  avatar: {
    "&.MuiAvatar-colorDefault": {
      backgroundColor: "#324F61",
      width: "34px",
      height: "34px",
      cursor: "pointer",
    },
  },
  username: {
    color: "#1F1F1F",
    letterSpacing: "0px",
    fontSize: "16px",
  },
  Menu: {
    "&.MuiMenu-paper": {
      border: "1px soild #8B8B8B",
    },
  },
  icon: {
    "&.MuiSvgIcon-root": {
      fontSize: "24px !important",
      "margin-left": "0px",
    },
  },
  active: {
    color: "#558BB9 !important",
  },
  customPadding: {
    paddingLeft: "10rem",
  },
});

const menuLink = [
  {
    id: 0,
    label: "Edit profile",
    url: "/sellerProfile",
  },
  {
    id: 1,
    label: "Account settings",
    url: "/account-settings",
  },
  {
    id: 2,
    label: "Log out",
    url: "/logout",
  },
];

function Header(props) {
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();
  const role = authHelper.getRole();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { showIcons, history, isExternal } = props;

  const isActive = useMediaQuery("(max-width: 770px)");
  const pagePath = useLocation().pathname;
  const openMenu = () => {
    setIsOpen(!isOpen);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickAvatar = e => {
    setAnchorEl(e.currentTarget);
  };

  const onListItemClick = item => {
    if (item.url === "/logout") {
      authHelper.logout(history);
    } else if (
      item.url === "/sellerProfile" &&
      authHelper.getRole() === "buyer"
    ) {
      history.push("/profile");
    } else {
      history.push(item.url);
    }
    setAnchorEl(null);
  };

  const isCredentialScreen =
    history.location.pathname !== "/login" &&
    history.location.pathname !== "/register";

  return (
    <div
      className={`${
        isActive
          ? isOpen
            ? classes.containerMediaQuaryIsopen
            : classes.containerMediaQuary
          : `${classes.container} w-100 d-flex justify-content-between align-items-center p-4 flex-row header-drop-shadow`
      }`}
    >
      <div
        className={`${
          isOpen && isActive
            ? `d-block`
            : "d-flex justify-content-between align-items-center header-content flex-row"
        }`}
      >
        <div className="mx-2 mx-md-4 ">
          <Link
            href={isLoggedIn ? "/find-companies" : "/"}
            className="font-weight-bold text-decoration-none"
          >
            <img alt="village wellth logo" src={vwLogo} className="logo-link" />
          </Link>
        </div>
        {!isExternal && !props.showOnboarding && isCredentialScreen && (
          <>
            <div className={`${isActive ? (isOpen ? "" : "d-none") : "ml-4"}`}>
              <Link
                className={`${
                  isOpen && isActive ? `${classes.topNavBarLink}` : ""
                } header-link-color text-decoration-none header-link`}
                href="/find-buyers"
              >
                Search Buyers
              </Link>
            </div>
            <div className={`${isActive ? (isOpen ? "" : "d-none") : "ml-4"}`}>
              <Link
                className={`${
                  isOpen && isActive ? `${classes.topNavBarLink}` : ""
                } header-link-color text-decoration-none header-link`}
                href="https://villagewellth-7216694.hs-sites.com/prequalification"
              >
                Financing
              </Link>
            </div>
            <div
              className={`${isActive ? (isOpen ? "" : "d-none") : "ml-4 mr-3"}`}
            >
              <Link
                className={`${
                  isOpen && isActive ? `${classes.topNavBarLink}` : ""
                } header-link-color text-decoration-none header-link`}
                href="https://blog.villagewellth.com/"
              >
                Resources
              </Link>
            </div>
          </>
        )}
      </div>

      <div
        className={`${
          !isLoggedIn
            ? isActive
              ? isOpen
                ? classes.topnavOpen
                : classes.topnav
              : "d-flex justify-content-end align-items-center log-in header-content flex-md-row flex-column "
            : "d-flex justify-content-end align-items-center log-in header-content flex-md-row flex-column "
        }`}
      >
        {isLoggedIn && !!role && !isExternal && (
          <div className="d-md-block"></div>
        )}
        {isLoggedIn && !isExternal ? (
          <>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className={`fs-2 font-regular mx-2 mb-0 d-none d-md-block ${classes.username}`}
              >
                {JSON.parse(window.localStorage.getItem("userDetails"))
                  .first_name?.concat(
                    ` ${
                      JSON.parse(window.localStorage.getItem("userDetails"))
                        .last_name
                    }`
                  )
                  ?.trim() ||
                  JSON.parse(window.localStorage.getItem("userDetails")).email}
              </p>
              <Avatar
                className={classes.avatar}
                src={""}
                onClick={onClickAvatar}
              >
                <Person className={classes.icon} />
              </Avatar>
            </div>
            <Menu
              id="simple-menu"
              className={`user-menu ${classes.Menu}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              keepMounted={true}
              autoFocus={true}
              open={Boolean(anchorEl)}
              onClose={onMenuClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              TransitionComponent={Fade}
            >
              <List dense={true}>
                {menuLink.map((value, index) => (
                  <React.Fragment key={index}>
                    {(!props.showOnboarding || value.id === 2) && (
                      <ListItem
                        key={value.id}
                        button
                        onClick={() => onListItemClick(value)}
                      >
                        <ListItemText primary={value.label} />
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Menu>
          </>
        ) : (
          <>
            {!isExternal && isCredentialScreen && (
              <div className="d-flex justify-content-center align-items-center pb-2">
                <Link
                  className={` font-bold mx-4 ${
                    isActive
                      ? isOpen
                        ? `${classes.topNavBarLink} header-link-color`
                        : ""
                      : "text-decoration-none pr-4 font-weight-bold header-link"
                  }`}
                  href="/login"
                >
                  Log in
                </Link>

                {isOpen && isActive ? (
                  <Link
                    component={Link}
                    href="/register"
                    className={`${classes.button} ${
                      isActive
                        ? isOpen
                          ? `${classes.topNavBarLink}  header-link-color`
                          : ""
                        : "ml-3 mr-2 mr-md-4"
                    }`}
                    variant="outlined"
                  >
                    Create Account
                  </Link>
                ) : (
                  <Button
                    component={Link}
                    href="/register"
                    className={`${isActive ? "" : "ml-3 mr-2 mr-md-4"} ${
                      classes.button
                    } `}
                    variant="outlined"
                  >
                    Create Account
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(Header);
