import API from './ApiBase';
import _ from "lodash";

export const getEducationVideos = async () => {
  return await API.get(`/api/v1/educational_content/`);
}

export const getDealConnects = async (pageNumber, filter, sortType) => {
  let params = {};

  if (sortType) {
    params['sort_by'] = sortType;
  }

  if (filter) {
    filter = _.pickBy(filter, (value, key) => {
      if (Array.isArray(value) &&  _.includes(value, '')) {
        value = [];
      }
      return !(value === undefined || value === '' || (Array.isArray(value) && value.length === 0));
    });
    params['filter'] = filter;
  }

  if (pageNumber) {
    params = {
        ...params,
        'page': pageNumber,
        'page_size': 9
    }
  }
  return await API.get(`/api/v1/sellers/`,{params});
}


export const getMarketplaceDealConnects = async (pageNumber, filter, sortType) => {
  let params = {};

  if (sortType) {
    params['sort_by'] = sortType;
  }

  if (filter) {
    filter = _.pickBy(filter, (value, key) => {
      if (Array.isArray(value) &&  _.includes(value, '')) {
        value = [];
      }
      return !(value === undefined || value === '' || (Array.isArray(value) && value.length === 0));
    });
    params['filter'] = filter;
  }

  if (pageNumber) {
    params = {
        ...params,
        'page': pageNumber,
        'page_size': 9
    }
  }
  return await API.get(`/api/v1/listing/business/`,{params});
}

export const getSellers = async () => {
  return await API.get(`/api/v1/buyers/dealconnect/`);
}

export const connectWithSelectedSellers = async (payload) => {
  return await API.post(`/api/v1/buyers/dealconnect/`, payload);
}

export const getLegalDocs = async () => {
  return await API.get(`/api/v1/legal_document/`);
}

export const getMyDeals = async () => {
  return await API.get(`/api/v1/buyers/dealconnect/documents/`);
}

export const sendVideoWatched = async (id) => {
  return await API.get(`/api/v1/educational_content/${id}/`);
}

// export const connectWithSeller = async (payload) => {
//   return await API.post(`/api/v1/user/dealconnect/`, payload);
// }
export const connectWithBuyer = async (payload) => {
  return await API.post(`/api/v1/buyers/buyerconnect/`, payload);
}

export const connectWithAllSellers = async () => {
  return await API.post(`/api/v1/user/dealconnect/all/`);
}

export const sendFinancialVerification = async () => {
  return await API.get(`/api/v1/buyers/financial-verification`);
}
