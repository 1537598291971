import React from 'react'

import { checkmarkCircle } from '../../../assets/images'

function Item({ text }) {
  return (
    <div className='d-flex align-items-center'>
      <img src={checkmarkCircle}></img>
      <p className='font-light p-2'>{ text }</p>
    </div>
  )
}

export default Item
