export const SHARE_PROFILE_MODAL = 'SHARE_PROFILE_MODAL';
export const TERMS_MODAL = 'TERMS_MODAL';
export const DASHBOARD_MODAL = 'DASHBOARD_MODAL';
export const SEARCH_TARGET_SUCCESS = 'SEARCH_TARGET_SUCCESS';
export const VIDEO_MODAL = 'VIDEO_MODAL';
export const LEGAL_DOCS_MODAL = 'LEGAL_DOCS_MODAL';
export const DEAL_CONNECT_VERIFICATION_MODAL = 'DEAL_CONNECT_VERIFICATION_MODAL';
export const DEAL_CONNECT_SUCCESS_MODAL = 'DEAL_CONNECT_SUCCESS_MODAL';
export const BUYER_CONNECT_SUCCESS_MODAL = 'BUYER_CONNECT_SUCCESS_MODAL';
export const ADVISOR_BIO_MODAL = 'ADVISOR_BIO_MODAL';
export const WELCOME_MODAL = 'WELCOME_MODAL';
export const SUCCESS_MODAL = 'SUCCESS_MODAL';
export const ABOUT_LOOKING_FOR_MODAL = 'ABOUT_LOOKING_FOR_MODAL';
export const SHARE_PROFILE_BY_EMAIL_MODAL = 'SHARE_PROFILE_BY_EMAIL_MODAL';
export const ADVISOR_VERIFICATION_MODAL = 'ADVISOR_VERIFICATION_MODAL';
export const ADVISOR_CONNECT_SUCCESS_MODAL = 'ADVISOR_CONNECT_SUCCESS_MODAL';
export const TERMS_OF_USE_MODAL = 'TERMS_OF_USE_MODAL';
export const NON_DISCLOSURE_AGREEMENT_MODEL = 'NON_DISCLOSURE_AGREEMENT_MODEL';
export const FINANCIAL_VERIFICATION_MODAL = 'FINANCIAL_VERIFICATION_MODAL';
export const OUTREACH_AND_EXPOSURE_MODAL = 'OUTREACH_AND_EXPOSURE_MODAL';
export const DEAL_CONNECTS_MODAL = 'DEAL_CONNECTS_MODAL';
export const SEARCH_TARGET = 'SEARCH_TARGET';
export const GEO_LOCATION_MODAL = 'GEO_LOCATION_MODAL';
export const ASSESSMENT_REPORT_MODAL = 'ASSESSMENT_REPORT_MODAL';
export const SELECT_RANGE_MODAL = 'SELECT_RANGE_MODAL';
