import React, { useEffect, useState } from 'react'
import {
  FormContainerClass,
  LabelClass,
  OnboardingStepContainerClass,
  TitleClass,
  CenterNextButton,
  NextButton
} from "../shared/BootStrapStyling";
import BankShieldIcon from "../../../assets/images/bank_shield.svg"
import Button from "@material-ui/core/Button/Button";
import './index.scss';
import { FINANCIAL_VERIFICATION_MODAL, SUCCESS_MODAL } from "../../../shared/modals/constants";
import withModals from "../../withModals";
import { get } from "lodash";
import { sendFinancialVerification } from "../../../shared/apis/DashboardService";
import CustomDialog from "../../CustomDialog";
import FinancialVerification from "../../FinancialVerification";
import SuccessMsg from "../../SuccessMsg";


const StepTen = ({
                   modals,
                   user,
                   toggleSuccessModal,
                   toggleFinancialVerificationModal,
                    onComplete
                 }) => {


  const [connectStatusMessage, setConnectStatusMessage] = useState('');
  const [verificationState, setVerificationState] = useState(null)
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (user) {
      setVerificationState(get(user, 'flinks_status', 'NOT_STARTED'));
    }
  }, [user]);

  const handleClose = () => {
    setConnectStatusMessage('');
  };

  const handleVerificationButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleFinancialVerificationModal();
  };

  const handleAcceptFinancialVerification = async () => {
    try {
      const response = await sendFinancialVerification();
      if (response && response.status === 200) {
        toggleFinancialVerificationModal();
        setSuccessMessage('We will send you an email in the next 24 hours to access the next steps of the verification process.');
        toggleSuccessModal();
      } else {
        setConnectStatusMessage('Something went wrong');
      }
    } catch (e) {
      setConnectStatusMessage('Something went wrong');
    }
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div
        className={`${FormContainerClass}`}
        id="stepTen"
      >
        <div className='d-flex justify-content-center align-items-center'>
          <img alt='bank shield image' src={BankShieldIcon} />
        </div>
        <div className='text-center py-5 w-100'>
          <h1 style={{fontSize: '35px'}} className={`${TitleClass}`}>Village Wellth uses bank level security to keep your information</h1>
        </div>
        <div className='text-center text-wrap pb-5'>
          <label className={`${LabelClass}`}> Security</label>
          <p>We use 256-bit encryption to connect your financial <br /> information to Village Wellth. We never share
            your financial <br /> information without permission.</p>
        </div>
        <div className='d-flex justify-content-center'>
          <div className={`${CenterNextButton}`}>
            <Button onClick={handleVerificationButton} className={`${NextButton}`}>
              Continue
            </Button>
          </div>
        </div>
        <CustomDialog
          className='verification-modal'
          open={modals[FINANCIAL_VERIFICATION_MODAL]}
          toggle={toggleFinancialVerificationModal}
          hideCloseButton
        >
          <FinancialVerification
            dismiss={onComplete}
            handleAcceptFinancialVerification={handleAcceptFinancialVerification}
            setVerificationState={setVerificationState}
            toggleFinancialVerificationModal={toggleFinancialVerificationModal}
          />
        </CustomDialog>
        <CustomDialog
          className=''
          open={modals[SUCCESS_MODAL]}
          toggle={toggleSuccessModal}
          hideCloseButton
        >
          <SuccessMsg message={successMessage} toggleSuccessModal={toggleSuccessModal} />
        </CustomDialog>
      </div>
    </div>
  )
}

const modalsProps = {
  toggleSuccessModal: SUCCESS_MODAL,
  toggleFinancialVerificationModal: FINANCIAL_VERIFICATION_MODAL
};

export default withModals(StepTen, modalsProps);
