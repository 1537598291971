import React, { useState  , useRef, useEffect} from "react";
import {useMount} from 'ahooks'
import { extend, last } from 'lodash'
import BeginCreationProfile from './BeginCreationProfile'
import './index.scss'
import { Stepper , Step, StepLabel, Snackbar, Backdrop, CircularProgress } from "@material-ui/core";

import StepOne from './StepOne'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'
import StepTen from './StepTen'
import ConnectBankAccount from "./ConnectBankAccount";
import ProfileComplete from './ProfileComplete/ProfileComplete'

import {
    onboardProfileInfo,
    resetVerification,
    updateProfilePassport,
} from "shared/apis/UserService"
import { getIndustryTypes, getLookupProvinces } from 'shared/apis/ConfigService';
import {getLookupCitiesByProvince} from "../../shared/apis/ConfigService";
import {getLookupCountries} from 'shared/apis/ConfigService';
import AuthHelper from 'shared/helpers/AuthHelper';
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import scrollToTopControlller from "../../shared/hooks/ScrollToTopControlller"


const DEFAULT_STEP_PAGE = 0;

const steps = [
    {
        id: 0,
        label: 'Personal Details',
    },
    {
        id: 1,
        label: 'Work Experience',
    },
    {
        id: 2,
        label: 'Acquisition Experience',
    },
    {
        id: 3,
        label: 'Search Criteria',
    },
    {
        id: 4,
        label: 'Numbers',
    },
    {
        id: 5,
        label: 'Motivation',
        submit: true
    },
    {
        id: 6,
        label: 'Step 2 Start Verification'
    },
    {
        id: 7,
        label: 'Connect bank account'
    },
    {
        id: 8,
        label: 'Security'
    },
    {
        id: 9,
        label: 'Profile Complete'
    },
]
const OnBoarding = (props) => {

    const formData = useRef({});
    const {data} =  props
    const auth = new AuthHelper()
    const [isBeginCreate , setIsBeginCreate ] = useState(false)
    const [activeStep, setActiveStep] = useState(DEFAULT_STEP_PAGE)
    const [submitForm, setSubmitForm] = useState(false);
    const [user, setUser] = useState({})
    const [provincesOptions, setProvincesOptions] = useState([]);
    const [TerritoryBusiness , setTerritoryBusiness] = useState([])
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [citiesBusinessOptions, setCitiesBusinessOptions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [statusMessage, setStatusMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useMount(() => {
        window.scrollTo(0, 0)

        getData()
        getIndustriesOptions()
    })

    useEffect(() => {
        fetchCountriesInfo();
    }, []);

    useEffect(() => {
        if (activeStep === steps.indexOf(last(steps))) {
            props.setOnboardingComplete(true)
        }
    }, [activeStep]);

    useEffect(() => {
      if (submitForm) {
        onCompleteData();
      }
    }, [submitForm]);

    const convertStringToBoolean = (value) => {
        if (value == 'true') {
            return true;
        }
        else if (value == 'false') {
            return false;
        }
        return value;
    }

    const fetchCountriesInfo = async () => {
        try {
            const response = await getLookupCountries();
            setCountries(response.data);
        } catch (e) {
            setCountries([]);
        }
    };

    const fetchProvincesOptions = async (countryID , isLocationBussiness) => {
        try {
            const response = await getLookupProvinces(countryID);

            if(isLocationBussiness) {
              setTerritoryBusiness(response.data)
            }else {
                setProvincesOptions(response.data);
            }
        } catch (e) {
            setProvincesOptions([]);
        }
    }

    const fetchCitiesOptions = async (provinceID) => {
        try {
            const response = await getLookupCitiesByProvince(provinceID);
            setCitiesOptions(response.data);
        } catch (e) {
            setCitiesOptions([]);
        }
    }

    const fetchCitiesBusinessOptions = async (provinceID) => {
        try {
            const response = await getLookupCitiesByProvince(provinceID);
            setCitiesBusinessOptions(response.data);
        } catch (e) {
            setCitiesBusinessOptions([]);
        }
    }

    const getIndustriesOptions = async () => {
        try {
            const { data } = await getIndustryTypes()
            setIndustries(data)
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async() => {
        try {
            if (data.profile.country) {
                fetchProvincesOptions(data.profile.country);
                fetchCitiesOptions(data.profile.province);
            }
            if (data.profile.desired_business_country) {
                fetchProvincesOptions(data.profile.desired_business_country, true);
            } else if (data.profile.desired_business_province) {
                fetchProvincesOptions(41, true);
                data.profile.desired_business_country = 41;
            }

            if (data.profile.desired_business_province) {
                fetchCitiesBusinessOptions(data.profile.desired_business_province);
            }
            const profileUser = data.profile
            formData.current = {
                first_name : data.first_name,
                last_name : data.last_name,
                industries_details : profileUser.industries_details,
                user_industries : profileUser.user_industries,
                expertise : profileUser.expertise,
                buyer_type : profileUser.buyer_type,
                total_assets_available : profileUser.total_assets_available,
                available_cash : profileUser.available_cash,
                target_company_earnings :profileUser.target_company_earnings,
                purchase_with_others : profileUser.purchase_with_others,
                country: profileUser.country,
                province : profileUser.province ,
                city : profileUser.city,
                desired_business_province:profileUser.desired_business_province,
                desired_business_city:profileUser.desired_business_city,
                desired_business_country:profileUser.desired_business_country,
                hide_last_name : profileUser.hide_last_name,
                bio : profileUser.bio,
                is_with_brokerage : profileUser.is_with_brokerage,
                businesses_owned_code: null,
                linked_in_url: null,
                businesses_acquired_code: null,
                businesses_sold_code: null,
                last_acquisition_industry_id: null,
                last_sale_industry_id: null,
                target_company_earnings_type: null,
                target_company_price: [10000, 20000000],
                engaged_with_lending_institution: null,
            }
            setUser(data)
        } catch (err) {
            setUser(null);
            console.log(err)
        }
    }

    const formDefaultValues = {
      first_name : '',
      last_name : '',
      hideLastName : false ,
      industries_details : [{id : 0 , name : ''}],
      user_industries : [],
    };
    const onBeginCreateProfile = () => {
        window.scrollTo(0, 0)

        setIsBeginCreate(true)
    }
    const onNextStep = (value) => {
        window.scrollTo(0, 0)

        formData.current = extend(formData.current, value);
        setActiveStep((prevActiveStep) => {
            if (steps[prevActiveStep].submit) {
                setSubmitForm(true);
            }

            return prevActiveStep + 1;
        });
    }
    const skipToComplete= () => {
        window.scrollTo(0, 0)

        setActiveStep(steps.length - 1)
    }
    const onBackStep = (value) => {
        window.scrollTo(0, 0);
        formData.current = extend(formData.current, value);
        setActiveStep((prevActiveStep) => {
            if (activeStep === DEFAULT_STEP_PAGE ) {
                setIsBeginCreate(false);
                return 0
            }
            return prevActiveStep - 1
        });
    }
    const onCompleteData = async () => {
        setIsLoading(true)

        const boolConvert = (boolParam) => {
            if (boolParam === true) return 'Yes'
            if (boolParam === false) return 'No'
            return 'Unsure'
        }

        const stringConvert = (strParam) => {
            if (strParam === 'Yes') return true
            if (strParam === 'No') return false
            return null
        }

         const updatedValues = {
             ...user,
             first_name :  formData.current.first_name,
             last_name :  formData.current.last_name,
             profile : {
                 ...user.profile,
                 hide_last_name :  formData.current.hide_last_name,
                 industries_details :  formData.current.industries_details,
                 user_industries :  formData.current.user_industries,
                 expertise :  formData.current.expertise,
                 buyer_type :formData.current.buyer_type,
                 total_assets_available :  formData.current.total_assets_available,
                 available_cash :  formData.current.available_cash,
                 target_company_earnings : formData.current.target_company_earnings,
                 purchase_with_others :  formData.current.purchase_with_others,
                 country: formData.current.country || String(41),
                 country_name : Number(formData.current.country) === 41 ? 'Canada' : 'United State of America',
                 province :  formData.current.province ,
                 city :  formData.current.city,
                 desired_business_province: formData.current.desired_business_province,
                 desired_business_city: formData.current.desired_business_city,
                 desired_business_country: formData.current.desired_business_country,
                 bio :  formData.current.bio,
                 is_with_brokerage : convertStringToBoolean( formData.current.is_with_brokerage),
                 businesses_owned_code: formData.current.businesses_owned_code,
                 linked_in_url: formData.current.linked_in_url,
                 businesses_acquired_code: formData.current.businesses_acquired_code,
                 businesses_sold_code: formData.current.businesses_sold_code,
                 last_acquisition_industry_id: formData.current.last_acquisition_industry_id,
                 last_sale_industry_id: formData.current.last_sale_industry_id,
                 target_company_earnings_type: formData.current.target_company_earnings_type,
                 target_company_price: formData.current.target_company_price,
                 
                 engaged_with_lending_institution: stringConvert(formData.current.engaged_with_lending_institution,)
             }
         }
        const updatePersonalprofile = {
            ...updatedValues.profile ,
            ...user,
            first_name :  formData.current.first_name,
            last_name :  formData.current.last_name,
        }
        const formattedPassportProfile = {
            ...updatedValues.profile,
            purchase_with_others: boolConvert(formData.current.purchase_with_others,)
        }
        if(updatedValues.is_verified && parseInt(document.getElementById('availableCash').value) < parseInt(updatedValues.total_assets_available)) {
            await resetVerification();
        }
        const response_personal = await onboardProfileInfo(updatePersonalprofile);
        const response = await updateProfilePassport(formattedPassportProfile);
        if (response.status === 200 && response_personal.status === 200) {
            setStatusMessage('Updated successfully');
            setIsLoading(false)
            //store firstname last name  on localstorage
            let userDetails= window.localStorage.getItem('userDetails');
            userDetails =  parseInt(JSON.parse(userDetails))
            const updateUserDetails = {
                ...userDetails ,
                first_name :  formData.current.first_name,
                last_name :  formData.current.last_name,
            }
            auth.setUserDetails(updateUserDetails)
            if (activeStep === steps.indexOf(last(steps))) {
                props.setIsShowOnboarding(false)

                if (props.history.location.pathname.indexOf('seller/') === -1 && props.history.location.pathname.indexOf('buyer/') === -1) {
                    props.history.push('/find-companies')
                }
            }

        } else {
            setStatusMessage('Something went wrong');
            setIsLoading(false)
        }
    }

    const STEP_PAGES_LIST = [
        <StepOne defaultValue={formData.current} key={'stepOne'} countries={countries} step={activeStep} onSubmit = {onNextStep} onBack={onBackStep} />,
        <StepThree defaultValue={formData.current} key={'StepThree'} step={activeStep}  onSubmit = {onNextStep} onBack={onBackStep}/>,
        <StepFour defaultValue={formData.current} key={'StepFour'} industries={industries} step={activeStep} onSubmit = {onNextStep} onBack={onBackStep} />,
        <StepFive defaultValue={formData.current} key={'StepFive'} countries={countries} industries={industries} step={activeStep} onSubmit = {onNextStep} onBack={onBackStep}/>,
        <StepSix defaultValue={formData.current} key={'StepSix'} step={activeStep} onSubmit = {onNextStep} onBack={onBackStep}/>,
        <StepSeven defaultValue={formData.current} key={'StepSeven'} step={activeStep} onComplete = {onNextStep} onBack={onBackStep}/>,
        <StepEight defaultValue={formData.current} key={'StepEight'} step={activeStep} onComplete={onNextStep} onBack={onBackStep}/>,
        <ConnectBankAccount onNextStep={onNextStep} skipToComplete={skipToComplete}/>,
        <StepTen defaultValue={formData.current} key={'StepTen'} step={activeStep} onComplete={onNextStep}/>,
        <ProfileComplete />
    ]

    return (
        <section id ='onboadrding' className="mx-auto col-12 col-md-10">
            <BeginCreationProfile
                title = {'Step 1: Build your acquisition profile.'}
                subTitle = {'Your acquisition profile is your key to better deal flow.'}
                boldText = {'15 minutes'}
                subTitle2 = {'Estimated '}
                subTitle3 = {'to complete'}
                btnText = {'Start Building Profile'}
                onBeginCreateProfile = {onBeginCreateProfile}
                isBeginCreate={isBeginCreate}
            />
           {isBeginCreate &&
                <>
                    <Stepper alternativeLabel activeStep={activeStep} style={{display : 'none'}}>
                            {steps.map((step) => {
                                return (
                                    <Step key={step.id}>
                                        <StepLabel>
                                            {step.label}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                    </Stepper>
                    <div className="steps">{STEP_PAGES_LIST[activeStep]}</div>
                    {statusMessage && <Snackbar open={statusMessage} autoHideDuration={5000} onClose={() =>  setStatusMessage('')}>
                        <Alert onClose={() =>  setStatusMessage('')} severity={statusMessage === 'Updated successfully' ? 'success' : 'error'}>
                            {statusMessage}
                        </Alert>
                    </Snackbar>}

                </>

            }
             <Backdrop  open={isLoading}  id="loading-dialog">
                         <CircularProgress />
             </Backdrop>
        </section>
    )
}

export default withRouter(OnBoarding);
