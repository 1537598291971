import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import AuthHelper from "shared/helpers/AuthHelper";
import Header from "components/Header";
import Footer from "components/Footer";
import ProfileIcon from "assets/images/curved_profile.svg";
import FilterIcon from "assets/images/filter.svg";
import SearchIcon from "assets/images/search.svg";
import ToolsIcon from "assets/images/tools.svg";

import { makeStyles } from "@material-ui/core";

const FEATURES = {
  Sr: [
    {
      title: "Search and discover 1000’s of active buyers",
      img: SearchIcon,
    },
    {
      title: "Access to resources & education",
      img: ToolsIcon,
    },
    {
      title: "Buyer matching & notifications",
      img: FilterIcon,
      comingSoon: true,
    },
  ],
  Br: [
    {
      title: "Discover 1000’s of marketplace listings",
      img: SearchIcon,
    },
    {
      title: "Be discovered by our seller network looking for buyers like you",
      img: ProfileIcon,
    },
    {
      title: "Access to resources & education",
      img: ToolsIcon,
    },
    {
      title: "Listing matching & notifications",
      img: FilterIcon,
      comingSoon: true,
    },
  ],
};

const useStyles = makeStyles({
  p: { borderRadius: "25px", boxShadow: "2px 4px 8px 2px rgba(1, 0, 0, 0.2)" },
});
const AccountV2Layout = ({ component: Component, enableRedirect, ...rest }) => {
  const classes = useStyles();
  const authHelper = new AuthHelper();
  let isLoggedIn = authHelper.isLoggedIn();
  const [role, setRole] = useState("Br");
  const isCredentialScreen = rest.path !=='/login' && rest.path !== '/register'
  return (
    <Route
      {...rest}
      render={matchProps => {
        if (enableRedirect && isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: matchProps.location },
              }}
            />
          );
        }
        return (
          <div className="c-account-layout">
            <Header showIcons={false} />
            <main className="c-layout__main c-account-layout__main container-fluid">
     
              
                <div className="register col-12 col-md-5 pr-0 justify-content-center align-items-center auth-form">
                  <Component
                    {...rest}
                    {...matchProps}
                    setRole={setRole}
                    role={role}
                  />
          
                
                {/* <div className="c-account-component col-12 col-md-5 offset-md-2 my-2 my-md-5 login-testimonials d-flex justify-content-center flex-column"> */}
                {/* {FEATURES[role] && FEATURES[role].map(({ title, img, comingSoon }, index) => (
                  <div key={index} className={`d-flex ${index === (FEATURES[role].length - 1) ? 'mb-0' : 'mb-2 mb-md-5'}`}>
                    <img className="mr-3" src={img} alt="user" width={28} height={28} />
                    <div className="d-inline-table">
                      <span>{title}</span>
                      {comingSoon &&
                        <div className="coming-soon">Coming Soon</div>
                      }
                    </div>
                  </div>
                ))} */}
                {/* </div> */}
              </div>
            </main>
            {isCredentialScreen && <Footer />}
          </div>
        );
      }}
    />
  );
};

export default AccountV2Layout;
