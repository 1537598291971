import React, { Suspense } from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import Loading from "components/Loading/index";
import DefaultLayout from "layouts/DefaultLayout/index";
import BuyerLayout from "layouts/BuyerLayout/index";
import SellerLayout from "layouts/SellerLayout/index";
import PrivateLayout from "layouts/PrivateLayout/index";
import AccountLayout from "./layouts/AccountLayout/index";
import AccountV2Layout from "./layouts/AccountV2Layout/index";
import ProfileLayout from './layouts/ProfileLayout';

const loading = Loading();

const Login = React.lazy(() => import('containers/LoginV2/index'));

const Register = React.lazy(() => import('containers/RegisterV2/index'));

const BrowseAll = React.lazy(() => import('containers/BrowseAll/index'));

const FindBuyers = React.lazy(() => import('containers/FindBuyers/index'));

const Find = React.lazy(() => import('containers/Find/index'));

const Evaluate = React.lazy(() => import('containers/Evaluate/index'));

const ManagedAcquisition = React.lazy(() => import('containers/ManagedAcquisition/index'));

const ActivatedSearch = React.lazy(() => import('containers/ActivatedSearch/index'));

const Manage = React.lazy(() => import('containers/Manage/index'));

const FQA = React.lazy(() => import('containers/FQA/index'));

const CalculatorAfford = React.lazy(() => import('containers/CalculatorAfford/index'));

const Favored = React.lazy(() => import('containers/Favored/index'));

const ComingSoon = React.lazy(() => import('containers/ComingSoon/index'));

const Home = React.lazy(() => import('containers/Home/index'));

const Dashboard = React.lazy(() => import('containers/Dashboard/index'));

const UserProfile = React.lazy(() => import('containers/UserProfile/index'));

const AccountSettings = React.lazy(() => import('containers/AccountSettings/index'));

// const PublicBuyerProfile = React.lazy(() => import('containers/PublicBuyerProfile/index'));

const PublicBuyerProfile = React.lazy(() => import('containers/PublicBuyerProfile/PublicBuyerProfile'));

const FakePublicBuyerProfile = React.lazy(() => import('containers/FakePublicBuyerProfile/index'));

const PublicDealConnect = React.lazy(() => import('containers/PublicDealConnect/index'));

const FindCompanies = React.lazy(() => import('containers/FindCompanies/index'));

const UploadMarketplace = React.lazy(() => import('containers/UploadMarketplace/index'));

const PublicSellerProfile = React.lazy(() => import('containers/PublicSellerProfile/index'));

const Contact = React.lazy(() => import('containers/Contact/index'));

const ResetPassword = React.lazy(() => import('containers/ResetPassword/index'));

const ForgetPassword = React.lazy(() => import('containers/ForgetPassword/index'));

const VwEducation = React.lazy(() => import('containers/VwEducation/index'));

const LegalDocs = React.lazy(() => import('containers/LegalDocs/index'));

const MyDeals = React.lazy(() => import('containers/MyDeals/index'));

const SellerProfilePersonal = React.lazy(() => import('containers/SellerProfilePersonal/index'));

const FlinksExternal = React.lazy(() => import('containers/FlinksExternal/index'));

const TermsOfUse = React.lazy(()=> import('containers/TermsOfUse/index') )

function App() {
    return (
        <Suspense fallback={loading}>
            <BrowserRouter>
                <Switch>
                    {<DefaultLayout exact enableRedirect={true} path="/" component={Home}/>}
                    <DefaultLayout exact path="/contact-us" component={Contact}/>
                    <DefaultLayout exact path="/buyers" component={BrowseAll}/>
                    <DefaultLayout exact path="/find-buyers" component={FindBuyers}/>
                    <DefaultLayout exact path="/companies" component={PublicDealConnect}/>
                    <DefaultLayout exact path="/find-companies" component={FindCompanies}/>
                    <DefaultLayout exact path="/find" component={Find}/>
                    <DefaultLayout exact path="/manage" component={Manage}/>
                    <DefaultLayout exact path="/calculator-afford" component={CalculatorAfford}/>
                    <DefaultLayout exact path="/evaluate" component={Evaluate}/>
                    <DefaultLayout exact path="/managed-acquisition" component={ManagedAcquisition}/>
                    <DefaultLayout exact path="/activated-search" component={ActivatedSearch}/>
                    <DefaultLayout exact path="/FQA" component={FQA}/>
                    <DefaultLayout exact path="/terms-of-use" component={TermsOfUse} />
                    <AccountV2Layout exact enableRedirect={true} path="/register" component={Register}></AccountV2Layout>
                    <AccountV2Layout exact enableRedirect={true} path="/login" component={Login}></AccountV2Layout>
                    <AccountLayout exact path="/forget-password" component={ForgetPassword}></AccountLayout>
                    <AccountLayout exact path="/reset-password/" component={ResetPassword}></AccountLayout>
                    <DefaultLayout exact path="/upload-marketplace" component={UploadMarketplace}></DefaultLayout>
                    <SellerLayout exact path="/seller/dashboard" component={BrowseAll}></SellerLayout>
                    <SellerLayout exact path="/sellerProfile" component={SellerProfilePersonal}/>
                    <PrivateLayout exact path="/seller/:id" component={PublicSellerProfile}></PrivateLayout>
                    <BuyerLayout exact path="/dashboard" component={Dashboard}/>
                    <BuyerLayout exact path="/profile" component={UserProfile}/>
                    <BuyerLayout exact path="/account-settings" component={AccountSettings}/>
                    <PrivateLayout exact path="/browse-all" component={BrowseAll}/>
                    <BuyerLayout exact path="/favored" component={Favored}/>
                    <BuyerLayout exact path="/my-deals" component={MyDeals}/>
                    <BuyerLayout exact path="/vw-education" component={VwEducation}/>
                    <BuyerLayout exact path="/legal-docs" component={LegalDocs}/>
                    <BuyerLayout exact path="/deal-connect" component={PublicDealConnect}/>
                    <ProfileLayout exact path="/buyer/:id" component={PublicBuyerProfile}/>
                    <PrivateLayout exact path="/user/:id" component={FakePublicBuyerProfile}/>
                    <DefaultLayout exact path="/verify-buyer" component={FlinksExternal} isExternal={true}/>
                    <AccountLayout component={ComingSoon}></AccountLayout>
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}

export default App;
