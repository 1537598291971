import React from 'react'

import { Button } from '@material-ui/core';
import './index.scss'
import { bankVerification } from '../../../assets/images'
import {
  BackButton,
  FormContainerClass,
  NextButton,
  OnboardingStepContainerClass,
  TitleClass
} from "../shared/BootStrapStyling";

function ConnectBankAccount({ onNextStep, skipToComplete }) {

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <form id='buttons' className={`${FormContainerClass}`}>
        <div className='text-center'>
          <h1 className={`${TitleClass}`}>Connect your bank account</h1>
          <p className='form-label font-regular'>We use Flinks to authenticate your information. Upon connection we
            automatically verify your identity & stated amount available for investment.</p>
          <div className='d-flex justify-content-center align-items-center py-5 '><img alt='bank image'
                                                                                 src={bankVerification} /></div>
          <div className='flex-column text-center justify-content-center align-items-center py-2'>
            <Button type='submit' onClick={onNextStep} className={`h-auto py-2 px-lg-5 px-2 nextBtn`}>
              {'Continue'}
            </Button>
          </div>
            <Button type='submit' onClick={skipToComplete}
                    className={`backBtn h-auto py-2 px-lg-5 px-2`}>
              {'Continue without verification'}
            </Button>
        </div>
      </form>
    </div>
  )
}

export default ConnectBankAccount

