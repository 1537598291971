import React from 'react'

import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Item from './Item'
import '../shared/stepForm.scss'
import './index.scss'
import {
  TitleClass,
  FormContainerClass,
  OnboardingStepContainerClass,
  NextButton,
  CenterNextButton
} from "../shared/BootStrapStyling";

function ProfileComplete() {
  const history = useHistory()

  function onClickSubmit() {
    history.push('/find-companies')
  }

  return (
    <div  className={`${OnboardingStepContainerClass}`}>
      <form className={`${FormContainerClass}`}>
        <div className='form-body center'>
          <h1 className={`${TitleClass}`}>Profile Complete!</h1>
          <Item text={'Sellers can now find your profile and reach out if they have an opportunity match'} />
          <Item text={'Connect with vetted opportunities you find on Village Wellth'} />
          <Item text={'Update your profile and verification information anytime as things change'} />
        </div>
        <div id='buttons' className={`${CenterNextButton} pt-5`}>
          <Button type='submit' onClick={onClickSubmit} className={`h-auto w-25 p-2 border nextBtn`}>
            {'Finish'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ProfileComplete
