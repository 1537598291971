import moment from 'moment';
import {register, login, logout} from "../apis/AuthService";
import {ROLES} from "../constants";

export default class AuthHelper {
    registerFunc = async (payload) => {
        let {data} = await register(payload);
        if (data) {
            this.storeRequiredInfo(data);
            return data;
        }
    }

    loginFunc = async (payload) => {
        let {data} = await login(payload);
        if (data) {
            this.storeRequiredInfo(data);
            data.profile = data.profile || {}
            this.setRole(data.profile.role)
            return data;
        }
    }

    storeRequiredInfo = (data) => {
        let {token, ...rest} = data;
        this.setToken(token);
       // this.setExpiryTime(res['expires_in']);
        this.setUserDetails(rest);
    }

    isLoggedIn = () => {
        const token = this.getToken();
        return token && !this.isTokenExpired(token);
    }

    isTokenExpired = (token) => {
        try {
            let exp = window.localStorage.getItem('expiryTime');
            if (moment(exp) < moment()) {
                this.logout();
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    setToken = (idToken) => {
        window.localStorage.setItem('jwt', idToken);
    }

    setExpiryTime = (time) => {
        let expireTime = moment().add(time, 'seconds').toString();
        window.localStorage.setItem('expiryTime', expireTime);
    }

    setUserDetails = (userDetails) => {
        window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
    }

    getUserId = () => {
        let userDetails= window.localStorage.getItem('userDetails');
        return parseInt(JSON.parse(userDetails).id, 10)
    }

    getToken = () => {
        return window.localStorage.getItem('jwt')
    }

    logout = async (history) => {
        // await logout();
        localStorage.removeItem('jwt');
        localStorage.removeItem('expiryTime');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('role');
        history.push("/find-companies");
    }

    getRole = () => {
        return window.localStorage.getItem('role')
    }

    setRole = (roleKey) => {
        window.localStorage.setItem('role', ROLES[roleKey] || '')
    }

}
