import React, { useEffect, useRef ,useState } from 'react';
import { useMount } from 'ahooks';
import { Button, InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import './index.scss';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { isArray, isEmpty } from 'lodash'
import {
  BackButton, BreadcrumbsClass, ButtonContainer,
  CenterNextButton, EmptyDiv, FormContainerClass, LabelClass,
  OnboardingStepContainerClass,
  TitleClass,
  NextButton
} from "../shared/BootStrapStyling";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import RadioButton from "../StepThree/RadioButton";
import { getLookupCitiesByProvince, getLookupProvinces } from "../../../shared/apis/ConfigService"

const StepFive = ({ countries, defaultValue, industries, onBack, onSubmit }) => {
  const { control, errors, handleSubmit, setError, setValue, watch } = useForm({ mode: 'onSubmit' })

  const watchDesiredCountry = watch('desiredCountry')
  const watchDesiredProvince = watch('desiredProvince')

  const [targetCompanySize , settargetCompanySize] = useState(null)

  /* Select menu options */
  const [industriesOptions, setIndustriesOptions] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])
  const [provincesOptions, setProvincesOptions] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])

  const IsSubmitted = useRef(false);

  useMount(() => {
    setCountriesOptions(countries ?? [])
    setIndustriesOptions(industries ?? [])
    setValue('userIndustries', isEmpty(defaultValue?.user_industries) ? [] : defaultValue.user_industries)
    setValue('desiredCountry', defaultValue?.desired_business_country ?? '')
    setValue('desiredProvince', defaultValue?.desired_business_province ?? '')
    setValue('desiredCity', defaultValue?.desired_business_city ?? '')
    setValue('targetEarningsMin', isArray(defaultValue?.target_company_earnings) ? defaultValue.target_company_earnings[0] : 50000)
    setValue('targetEarningsMax', isArray(defaultValue?.target_company_earnings) ? defaultValue.target_company_earnings[1] : 10000000)
    setValue('targetEarningsType', defaultValue?.target_company_earnings_type ?? null)
    settargetCompanySize(defaultValue?.target_company_price || [10000, 20000000])
  });

  /* When country is selected, fetch provinces */
  useEffect(() => {
    if (watchDesiredCountry) {
      getProvincesOptions(watchDesiredCountry)
    }
  }, [watchDesiredCountry])

  /* When province is selected, fetch cities */
  useEffect(() => {
    if (watchDesiredProvince) {
      getCitiesOptions(watchDesiredProvince)
    }
  }, [watchDesiredProvince])

  const onSubmitStepFive = (data) => {
    const values = {
      ...defaultValue,
      desired_business_country: data.desiredCountry,
      desired_business_province: data.desiredProvince,
      desired_business_city: data.desiredCity,
      target_company_earnings: [Number(data.targetEarningsMin), Number(data.targetEarningsMax)],
      target_company_earnings_type: data.targetEarningsType,
      target_company_price: targetCompanySize,
      user_industries: data.userIndustries,
    }
    if (IsSubmitted.current) {
      onSubmit(values);
    } else {
      onBack(values);
    }
  };
    const onClickBack = () => {
        IsSubmitted.current = false;
    };

    const onClickSubmit = () => {
        IsSubmitted.current = true;
    };

    const getProvincesOptions = async (countryId) => {
      try {
        const { data } = await getLookupProvinces(countryId)
        setProvincesOptions(data)
      }
      catch (e) {
        console.log(e)
      }
    }

    const getCitiesOptions = async (provId) => {
      try {
        const { data } = await getLookupCitiesByProvince(provId)
        setCitiesOptions(data)
      }
      catch (e) {
        console.log(e)
      }
    }

    return (
      <div className={`${OnboardingStepContainerClass}`} >
        <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={3} /></div>
        <form
          id="stepFive"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitStepFive)}
          className={`${FormContainerClass}`}
        >
          <div>
            <h1 className={`${TitleClass}`}>
              Search Criteria
            </h1>
          </div>
          <label className={`${LabelClass}`}>Desired Price Range</label>
            <div className='d-flex align-items-center w-100'>
              <div className='w-100'>
                <p className='font-light'></p>
                <TextField
                  required
                  id="from-field"
                  placeholder='e.g. 500000'
                  fullWidth
                  isOnboarding
                  variant='outlined'
                  className='w-100'
                  value={targetCompanySize && targetCompanySize[0]}
                  onChange={(e) => {
                    let oldArray = [...targetCompanySize];
                    oldArray[0] = Number(e.target.value);
                    settargetCompanySize(oldArray)
                  }}
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
              </div>
              <div className='font-semi-bold px-2'>
                To
              </div>
              <div className='w-100'>
                <p className='font-light'></p>
                <TextField
                  required
                  id="to-field"
                  placeholder='e.g. 500000'
                  fullWidth
                  isOnboarding
                  variant='outlined'
                  className='w-100'
                  value={targetCompanySize && targetCompanySize[1]}
                  onChange={(e) => {
                    let oldArray = [...targetCompanySize];
                    oldArray[1] = Number(e.target.value);
                    settargetCompanySize(oldArray)
                  }}
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
              </div>
            </div>
          <div className='d-flex-column align-items-center w-100 py-3'>
            <label className={`${LabelClass}`}>Desired Earnings</label>
            <div className='radio-section px-1'>
              <Controller
                name="targetEarningsType"
                control={control}
                defaultValue={null}
                rules={{ required: "Select required"}}
                render={(props) => (
                  <>
                    <RadioButton
                      {...props} value={0} label='EBITDA' valueType="number"
                      state={props.value} group={'target-company-earnings-type'}
                    />
                    <RadioButton
                      {...props} value={1} label='SDE' valueType="number"
                      state={props.value} group={'target-company-earnings-type'}
                    />
                  </>
                )}
              />
            </div>
            <ErrorMessage className="error-message mt-1" errors={errors} name="targetEarningsType" as="p" />
            <div className='d-flex align-items-center'>
              <div className='w-100'>
                <p className='font-light'></p>
                <Controller
                  name="targetEarningsMin"
                  control={control}
                  defaultValue={''}
                  rules={{ required: "Field required" }}
                  render={(props) => (
                    <TextField
                      {...props} id="from-field" variant='outlined' className='w-100'
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    />
                  )}
                />
                <ErrorMessage className="error-message mt-1" errors={errors} name="targetEarningsMin" as="p" />
              </div>
              <div className='font-semi-bold px-2'>
                To
              </div>
              <div className='w-100'>
                <p className='font-light'></p>
                <Controller
                  name="targetEarningsMax"
                  control={control}
                  defaultValue={''}
                  rules={{ required: "Field required" }}
                  render={(props) => (
                    <TextField
                      {...props} id="from-field" variant='outlined' className='w-100'
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    />
                  )}
                />
                <ErrorMessage className="error-message mt-1" errors={errors} name="targetEarningsMax" as="p" />
              </div>
            </div>
          </div>
          <div className='d-lg-flex d-flex-column pb-4 pt-1 w-100 py-3'>
            <div className='w-100'>
              <label className={`${LabelClass}`}>Select up to 5 industries of interest</label>
              <Controller
                name="userIndustries"
                control={control}
                rules={{ validate: v => {
                    setError('userIndustries', { type: 'selectionLength' })
                    return (v.length <= 5 && v.length > 0) ||  'Select 1 to 5 industries'
                  }
                }}
                render={(props) => (
                  <Select
                    multiple
                    displayEmpty
                    disabled={isEmpty(industriesOptions)}
                    className="w-100"
                    variant='outlined'
                    defaultValue={!isEmpty(props.value) ? props.value : []}
                    inputProps={{ 'aria-label': 'multi industry select' }}
                    renderValue={() => {
                      if(isEmpty(props.value)) return <em>Select Industries</em>
                      return mapIdToName(props.value, industriesOptions)
                    }}
                    {...props}
                    value={!isEmpty(props.value) ? props.value : []}
                  >
                    <MenuItem disabled value=""><em>{'Select Industries'}</em></MenuItem>
                    {industriesOptions?.map(industry => (
                      <MenuItem value={industry.id} key={industry.name + industry.id}>{industry.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage className="error-message mt-1" errors={errors} name="userIndustries" as="p" />
            </div>
          </div>
          <div className='w-100 col-lg-8 col-lg-12 col-12 p-0 m-1'>
            <label className={`${LabelClass}`} id="location">What location are you searching in?</label>
            <div className='d-lg-flex d-flex-column align-items-center justify-content-around pb-4 pt-1'>
              <div className='w-100'>
                <Controller
                  name="desiredCountry"
                  control={control}
                  defaultValue={''}
                  rules={{ required: "Country required" }}
                  render={(props) => (
                    <Select
                      displayEmpty
                      disabled={isEmpty(countriesOptions)}
                      className="w-100"
                      variant='outlined'
                      inputProps={{ 'aria-label': 'location select' }}
                      {...props}
                    >
                      <MenuItem disabled value=""><em>{'Select Country'}</em></MenuItem>
                      {countriesOptions?.map(country => (
                        <MenuItem value={country.id} key={country.name + country.id}>{country.name}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <ErrorMessage className="error-message mt-1" errors={errors} name="desiredCountry" as="p" />
              </div>
              <div className='px-lg-2 py-2 py-lg-0 w-100'>
                <Controller
                  name="desiredProvince"
                  control={control}
                  defaultValue={''}
                  rules={{ required: "Province required" }}
                  render={(props) => (
                    <Select
                      displayEmpty
                      disabled={isEmpty(provincesOptions)}
                      className="w-100"
                      variant='outlined'
                      inputProps={{ 'aria-label': 'location select' }}
                      {...props}
                    >
                      <MenuItem disabled value=""><em>{'Select Province'}</em></MenuItem>
                      {provincesOptions?.map(prov => (
                        <MenuItem value={prov.id} key={prov.name + prov.id}>{prov.name}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <ErrorMessage className="error-message mt-1" errors={errors} name="desiredProvince" as="p" />
              </div>
              <div className='w-100'>
                <Controller
                  name="desiredCity"
                  control={control}
                  defaultValue={''}
                  rules={{ required: "City required" }}
                  render={(props) => (
                    <Select
                      displayEmpty
                      disabled={isEmpty(citiesOptions)}
                      className="w-100"
                      variant='outlined'
                      inputProps={{ 'aria-label': 'location select' }}
                      {...props}
                    >
                      <MenuItem disabled value=""><em>{'Select City'}</em></MenuItem>
                      {citiesOptions?.map(city => (
                        <MenuItem value={city.id} key={city.name + city.id}>{city.name}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <ErrorMessage className="error-message mt-1" errors={errors} name="desiredCity" as="p" />
              </div>
            </div>
          </div>
          <div className={`${ButtonContainer}`}>
            <div>
              <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                      className={`${BackButton}`}>
                {'Previous'}
              </Button>
            </div>
            <div className={`${CenterNextButton}`}>
              <Button type='submit' onClick={onClickSubmit}  className={`${NextButton}`}>
                {'Next'}
              </Button>
            </div>
            <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
          </div>
        </form>
      </div>
    );
};

const findOptionObj = (options, value) => {
  return options.find(option => value === option.id)
}

const mapIdToName = (selectedValue, options) => {
  const currentSelections = selectedValue?.map(value => (
    findOptionObj(options, value).name
  ))

  return currentSelections.join(', ')
}

StepFive.defaultProps = {
};

StepFive.propTypes = {
};

export default StepFive;
