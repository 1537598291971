import React, {useState, useEffect} from "react";
import { sendLoginId, getConfigs } from "../../shared/apis/FlinksService";
import Loading from "../Loading";

function Flinks(props) {

  const [loginId, setLoginId] = useState("");
  const [isDemo, setIsDemo] = useState(false);
  const [verificationLoaded, setVerificationLoaded] = useState(false);
  const {showConfirm, setVerificationState, setShowMessage, setVerificationCompleted} = props;

  useEffect(() => {
    if(loginId) {
      sendLoginId(loginId);
    }
  }, [loginId])

  useEffect(() => {
    setIFrameLink().then(function(response) {
      if(response.data.flinks_demo_enabled) {
        setIsDemo(true);
      }
    })
  }, []);

  const handleFlinksMessage = (e) => {
    if(e && e.data) {
      if(e.data.step) {
        switch(e.data.step) {
          case "COMPONENT_LOAD_INSTITUTION_SELECTOR":
            setVerificationLoaded(true);
            break;
          case "REDIRECT":
            setVerificationCompleted && setVerificationCompleted(true);
            break;
        }
        
        if(setShowMessage) {
          if(e.data.step.toLowerCase().startsWith('submit')) {
            setShowMessage(1);
          }
          else if(e.data.institution) {
            setShowMessage(-1);
          }
          else {
            setShowMessage(0);
          }
        }
      }

      if(e.data.loginId && e.data.loginId !== loginId) {
        showConfirm && showConfirm();
        setLoginId(e.data.loginId);
        setVerificationState && setVerificationState("IN_PROGRESS");
      }
    }
  }

  const setIFrameLink = async () => {
    const response = await getConfigs();

    return response;
  }

  window.addEventListener('message', handleFlinksMessage);

  return (
    <div className="text-center iframe-container">
      {!verificationLoaded && <Loading className="iFrame-loader"></Loading>}
      <iframe height="430"
              className="w-100 my-3"
              src={"https://villagewellth-iframe.private.fin.ag/v2/?redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=Village Wellth&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true" + (isDemo ? "&demo=true" : "")}>
      </iframe>
    </div>
  )
}

export default Flinks;
