import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import ProfileIcon from "@material-ui/icons/Person";
import FavoredIcon from "@material-ui/icons/Stars";
import AddIcon from "@material-ui/icons/Add";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Menu from "@material-ui/icons/Menu";
import AuthHelper from "shared/helpers/AuthHelper";
import Link from "@material-ui/core/Link";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatBubbleRoundedIcon from "@material-ui/icons/ChatBubbleRounded";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  container: {
    height: 75,
    borderBottom: "2px solid #f2f2f2",
  },
  drawer: {
    width: 100,
    flexShrink: 0,
    position: "sticky",
    zIndex: 1,
    whiteSpace: "nowrap",
  },
  drawerIsActive: {
    width: "auto",
    flexShrink: 0,
    position: "sticky",
    zIndex: 1,
    whiteSpace: "nowrap",
  },
  topNavBarLink: {
    float: "none",
    display: "block",
    textAlign: "left",
    color: "#fff",
    padding: "15px",
    "&:hover, &:focus": {
      backgroundColor: "#f5f5f5",
      "&.MuiLink-root": {
        color: "#000",
      },
    },
  },
});

function SideBar(props) {
  const classes = useStyles();
  const isActive = useMediaQuery("(max-width: 680px)");
  const [isOpen, setIsOpen] = useState(false);
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();
  const { history } = props;
  const role = authHelper.getRole();

  let options = [];
  if (props.userRole == "seller") {
    options = [
      {
        title: "Browse all buyers",
        link: "/seller/dashboard",
        icon: <HomeIcon style={{ color: "#EFE5D0" }} />,
      },
      {
        title: "Profile",
        link: "/sellerProfile",
        icon: <ProfileIcon style={{ color: "#EFE5D0" }} />,
      },
    ];
  } else {
    options = [
      {
        title: "Dashboard",
        link: "/dashboard",
        icon: <HomeIcon style={{ color: "#EFE5D0" }} />,
      },
      {
        title: "Profile",
        link: "/personal",
        icon: <ProfileIcon style={{ color: "#EFE5D0" }} />,
      },
      {
        title: "Favored",
        link: "/favored",
        icon: <FavoredIcon style={{ color: "#EFE5D0" }} />,
      },
    ];
  }

  const getSelectedList = (link, idx) => {
    let selectedList;
    switch (window.location.pathname) {
      case "/":
      case "/buyer/onboarding":
      case "/seller/onboarding":
        selectedList = 0;
        break;
      case "/personal":
      case "/sellerProfile":
      case "/passport":
        selectedList = 1;
        break;
      case link:
        selectedList = idx;
        break;
    }
    return selectedList;
  };
  const openMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Drawer
      variant="permanent"
      className={`side-bar ${
        isActive ? `${classes.drawerIsActive}` : `${classes.drawer}`
      } `}
    >
      <List className="mt-4">
        {options &&
          options.map(({ title, link, icon }, idx) => (
            <ListItem
              title={title}
              button
              key={title}
              selected={getSelectedList(link, idx) === idx}
              onClick={() => props.history.push(link)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
            </ListItem>
          ))}
        {isActive && (
          <ListItem>
            <ListItemIcon>
              <Menu
                fontSize="large"
                onClick={openMenu}
                className="text-white"
              />
            </ListItemIcon>
          </ListItem>
        )}
      </List>
      {isOpen && isActive && (
        <div className="d-md-block">
          {isLoggedIn && !!role && (
            <div className="d-md-block">
              {role === "buyer" && (
                <Link
                  className={`${
                    isActive
                      ? `${classes.topNavBarLink} header-link-color text-decoration-none header-link `
                      : "d-none"
                  }`}
                  href="/calculator-afford"
                >
                  Affordability calculator
                </Link>
              )}
              <Link
                className={`${
                  isActive
                    ? `${classes.topNavBarLink} header-link-color text-decoration-none header-link `
                    : "d-none"
                }`}
                href="/find-buyers"
              >
                Browse all buyers
              </Link>
              {role === "buyer" && (
                <Link
                  className={`${
                    isActive
                      ? `${classes.topNavBarLink} header-link-color text-decoration-none header-link `
                      : "d-none"
                  }`}
                  href="/deal-connect"
                >
                  Browse all companies
                </Link>
              )}
            </div>
          )}
          {isLoggedIn && (
            <>
              <Link
                className={`${
                  isActive
                    ? `${classes.topNavBarLink} header-link-color  mr-4`
                    : "d-none"
                }`}
                component={Link}
                onClick={() => authHelper.logout(history)}
                color="primary"
              >
                Logout
              </Link>
            </>
          )}
        </div>
      )}
    </Drawer>
  );
}

export default withRouter(SideBar);
