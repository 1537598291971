import {
  Drawer,
  Box,
  Typography,
  Link,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { menuIcon } from "../../assets/images";
import "./MenuDrawer.scss";
import React, { useState } from "react";

const MenuDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const iconStyle = makeStyles;
  return (
    <>
      <button
        className="drawer-button"
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <img src={menuIcon} />
      </button>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box p={2} width="200px" textAlign="center" role="presentation">
          <Typography variant="h5" component="div">
            Menu
          </Typography>
          <hr />
          <Link
            className="header-link-color text-decoration-none header-link"
            href="/find-buyers"
          >
            Search Buyers
          </Link>
          <hr />
          <Link
            className="header-link-color text-decoration-none header-link"
            href="https://villagewellth-7216694.hs-sites.com/prequalification"
          >
            Financing
          </Link>
          <hr />
          <Link
            className="header-link-color text-decoration-none header-link"
            href="https://blog.villagewellth.com/"
          >
            Resources
          </Link>
          <hr />
        </Box>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
