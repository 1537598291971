import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMount } from "ahooks"
import { Button, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import RadioButton from './RadioButton'

import './index.scss';
import {
  BackButton,
  BreadcrumbsClass, ButtonContainer, CenterNextButton,
  FormContainerClass, LabelClass, NextButton,
  OnboardingStepContainerClass,
  TitleClass,
  EmptyDiv
} from "../shared/BootStrapStyling";

const StepThree = ({ defaultValue, onBack, onSubmit }) => {
  const { handleSubmit } = useForm({ mode: 'onSubmit' })

  const IsSubmitted = useRef(false);

  const [businessesOwned, setBusinessesOwned] = useState(null)
  const [linkedIn, setLinkedIn] = useState('')

  useMount(() => {
    setBusinessesOwned(defaultValue?.businesses_owned_code ?? null)
    setLinkedIn(defaultValue?.linked_in_url ?? '')
  })

  const onSubmitStepThree = () => {
    const updatedValues = {
      ...defaultValue,
      businesses_owned_code: businessesOwned,
      linked_in_url: linkedIn,
    }
    if (IsSubmitted.current) {
      onSubmit(updatedValues);
    } else {
      onBack(updatedValues);
    }
  };
  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={1} /></div>
      <form
        id="stepThree"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepThree)}
        className={`${FormContainerClass}`}
      >
        <div className='form-body'>
          <h1 className={`${TitleClass} pb-5`}>Work experience & skills</h1>
          <label className={`${LabelClass} pt-3`}>How many businesses have you owned?</label>
          <div className='radio-section px-1' onChange={e => setBusinessesOwned(Number(e.target.value))}>
            <RadioButton value={0} label='Zero' state={businessesOwned} group={'businesses-owned'} />
            <RadioButton value={1} label='1-2' state={businessesOwned} group={'businesses-owned'} />
            <RadioButton value={2} label='2+' state={businessesOwned} group={'businesses-owned'}/>
          </div>
          <label className={`${LabelClass} pt-3`}>LinkedIn URL</label>
          <TextField
            required
            id="linkdin"
            name='linkdin'
            variant='outlined'
            className='w-100'
            placeholder='ex. https://www.linkedin.com/in/mikevillagewellth/'
            value={linkedIn}
            onChange={e => setLinkedIn(e.target.value)}
          />
          <div className='d-flex-column lg:d-flex-row align-items-center w-100 pb-4 pt-1 px-1'>
            <RemoveRedEyeOutlined fontSize='small' />
            <small style={{color: '#7D7F80;', fontFamily: 'Spartan-Bold' }} className='text-muted px-2'> Not be displayed in searchable profile</small>
          </div>
        </div>
        <div className={`${ButtonContainer}`}>
          <div>
            <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                    className={`${BackButton}`}>
              {'Previous'}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button type='submit' onClick={onClickSubmit}  className={`${NextButton}`}>
              {'Next'}
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
        </div>
      </form>
    </div>
  );
};

export default StepThree;
