import React from 'react'
import BeginCreationProfile from "../BeginCreationProfile";
const StepEight = ({ onComplete }) => {
  return(
      <BeginCreationProfile
        title = {'Step 2: Verify your financial credibility'}
        subTitle = {'By connecting your bank, we automatically verify your identity & stated amount available for investment'}
        btnText = {'Start Verification'}
        step2={true}
        onBeginCreateProfile={onComplete}
      />
  )
}

export default StepEight
