import React from "react";
import Box from "@material-ui/core/Box/Box";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import { makeStyles } from "@material-ui/core";

const StepBox = ({ stepNumber, stepTitle, stepImage, disabled }) => {
  const useStepsStyle = makeStyles({
    iconSize: {
      width: '45px',
      height: '45px',
    },
  });

  const stepsClasses = useStepsStyle();


  return (
    <Box
      boxShadow={3}
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '2px',
        padding: '10px',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      <div className='container'>
        <div className='row d-flex flex-lg-row flex-column align-items-center justify-content-between'>
          <div className='d-none d-lg-block'>
            <img src={stepImage} className={disabled && 'disabled'} />
          </div>
          <div className='d-flex flex-column align-items-start'>
            <p className={disabled ? 'disabled h6 h-md-4 text-secondary' : ' h6 text-secondary h-md-4'}>{stepNumber}</p>
            <p className={disabled ? 'disabled h5 h-md-2' : ' h5 h-md-2'} style={{ fontFamily: "Spartan-SemiBold" }}>{stepTitle}</p>
          </div>
          <div className='d-none d-lg-block'>
            <Brightness1OutlinedIcon color="primary"
                                     className={`${disabled && 'disabled'} ${stepsClasses.iconSize}`} />
          </div>
        </div>
      </div>
    </Box>
  )

}

export default StepBox
