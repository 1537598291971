import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import {toast} from 'react-toastify';
import * as FullStory from '@fullstory/browser';
import prod from "./shared/config"

// Call it once in your app. At the root of your app is the best place
toast.configure({
    autoClose: 3500,
    hideProgressBar: true,
    bodyClassName: 'p-2'
});

FullStory.init({ orgId: 'ZS2JH' });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
