import React, { useState } from "react"
import Divider from "@material-ui/core/Divider";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import Link from '@material-ui/core/Link';
import {InputBase, makeStyles} from "@material-ui/core";
import vwLogo from 'assets/images/vw-logo-light.png';
import withModals from "../withModals";
import CustomDialog from "../CustomDialog/index";
import Button from "@material-ui/core/Button/Button";
import {TERMS_OF_USE_MODAL} from "shared/modals/constants";
import {withRouter} from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { joinNewsletter } from "shared/apis/WPBlogsService";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles({
    divider: {background: '#f1e9d7'},
    socialIcon: {width: 16, height: 16},
    noButton: {
        backgroundColor: 'transparent',
        border: '1px solid',
        marginRight: '30px'
    },
    button1: {
        textTransform: 'none',
        textAlign: 'center',
        color : '#FFFFFF',
        backgroundColor:'#558BB9',
        borderRadius: '4px !important',
        border :'0px',
        fontFamily:"Spartan-SemiBold",
        fontSize : '15px',
        letterSpacing : '0px',
        "&:hover":{
            backgroundColor:'#5687b0',
        }
    },
    input : {
        'background': 'transparent',
        'color' :'#558BB9',
        'border': '#558BB9 solid 1px',
        'border-radius': '4px',
        'padding': '0px 20px',
        margin: '0px  10px 0px 0px',
        opacity:'1',
    },
    bottomFotter : {
        background : '#324F61',
        height : '50px'
    },
    leading :  {
        fontSize : '13px',
        letterSpacing : '0px',
        color: '#324F61',
        width: '353px',
    },

});

const Footer = (props) => {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [success, setSuccess] = useState("");
    const [fail, setFail] = useState("");

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleJoinNewsletter = async () => {
        if (!email) {
          setEmailErr("Email is required")
          return;
        } else if (!validateEmail(email)) {
          setEmailErr("Email is not valid")
          return;
        }
        // try {
          const response = await joinNewsletter(email);
          console.log(response)
          if (response.data.status === 200) {
            setFail("")
            setSuccess(response.data.message)
        } else {
            setSuccess("")
            setFail(response.data.message)
        }
      }
    return (
        <div className="footer mt-2">
            <div className="d-flex flex-row justify-content-around p-4 footer-subcontainer">
                <div className="w-50 footer-subscribe-section">
                    <form className="mt-5 mx-2 px-2"  onSubmit={handleSubmit(handleJoinNewsletter)}>
                            <p className={`${classes.leading} u-color-text font-bold`}>Subscribe to our Newsletter!</p>
                            <p className={`${classes.leading} u-color-leading-text font-regular`}>
                                Join our newsletter to stay up to date with useful information on how to
                                make your business buying experience successful.
                            </p>
                            {success && <Alert className="response-message" severity="success">{success}</Alert>}
                            {fail && <Alert className="response-message" severity="error">{fail}</Alert>}
                            <div className="row d-flex align-bottom mb-3 mt-4">
                              <div className="col-12 col-md-6">
                                <InputBase
                                    id="email"
                                    type="email"
                                    className={`w-100 h-100 ${classes.input}`}
                                    name="email"
                                    placeholder="Email Address"
                                    onChange={(e)=>{
                                    setEmail(e.target.value)
                                    }}
                                    inputRef={
                                    register({
                                        required: "Please enter your email address"
                                    })}
                                />
                              </div>
                              <div className="col-12 col-md-auto mt-3 mt-md-0">
                                <Button type="submit" className={`${classes.button1} small-button mt-0 w-100`} variant="outlined">
                                             Subscribe
                                </Button>
                              </div>
                            </div>
                            <ErrorMessage className="error-message" errors={errors} name="email" as="p" />
                        </form>
                </div>
                <div className="w-100 w-md-50 d-flex flex-row justify-content-end pt-2 mt-2 mt-md-5 px-2 footer-links-0">
                    <div className="mobile-border-top w-100 w-md-25 mb-4 pt-4 pt-md-0 mb-md-0 mx-0 mx-md-4 mr-5 footer-links-1">
                        <div className="d-block ">
                        <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="/companies">
                                Search Companies
                        </Link>
                        </div>

                        <div className="d-block pt-2">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="/buyers">
                            Search Buyers
                            </Link>
                        </div>

                        <div className="d-block pt-2">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="https://villagewellth-7216694.hs-sites.com/prequalification">
                            Financing
                            </Link>
                        </div>

                        <div className="d-block pt-2">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="http://blog.villagewellth.com/">
                            Resources
                            </Link>
                        </div>
                    </div>
                    <div className="w-100 mobile-border-top w-md-25 footer-links-2 mb-4 pt-4 pt-md-0">
                        <div className="d-block">
                                <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="/contact-us">
                                        Contact Us
                                </Link>
                                </div>
                                <div className="d-block pt-2">
                                    <Link className='u-color-text font-semi-bold  footer footer-contact-us' href="/terms-of-use">
                                        Terms of Use
                                    </Link>
                                </div>
                                <div className="d-block pt-2">
                                    <a href="https://www.freeprivacypolicy.com/live/f7f00906-df08-473a-b965-00cd3a7cb1dc" className="u-color-text font-semi-bold  footer footer-contact-us" target="_blank">Privacy Policy</a>
                                </div>
                        </div>
                </div>
            </div>
            <div className={`${classes.bottomFotter} d-flex flex-row justify-content-between align-items-center`}>
                <p className={`u-white-somke-color ml-4 mb-0 footer-vw-copyright font-regular`}>&copy; Village Wellth {new Date().getFullYear()}</p>
                <div className="d-flex flex-row me-4">
                        <Link className='u-white-somke-color' href="https://www.instagram.com/villagewellth/?hl=en" target="_blank">
                            <InstagramIcon className={`ml-3 mr-3 ${classes.socialIcon}`}></InstagramIcon>
                        </Link>
                        <Link className='u-white-somke-color' href="#" target="_blank">
                            <TwitterIcon  className={`mr-3 ${classes.socialIcon}`}></TwitterIcon>
                        </Link>
                        <Link className='u-white-somke-color' href="https://www.facebook.com/VillageWellth/" target="_blank">
                            <FacebookIcon className={`mr-3 ${classes.socialIcon}`} ></FacebookIcon>
                        </Link>

                    </div>
            </div>
        </div>
    )
}
export default withRouter(Footer);
