import React, {Fragment, useState} from 'react';
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import VerifyIcon from 'assets/images/verify.svg';
import Flinks from 'components/Flinks';
import { sendNoConsentNotification } from "../../shared/apis/FlinksService";
import './FinancialVerification.scss';
import { NextButton } from "../Onboarding/shared/BootStrapStyling";

const useStyle = makeStyles((theme) =>({
    button1: {
        textTransform: 'none',
        textAlign: 'center',
        marginTop: '38px',
    },
    noButton: {
        backgroundColor: 'transparent',
        border: '1px solid',
        marginRight: '30px'
    },
    verificationTitle: {
        fontSize: '30px',
        fontFamily: 'lora-bold',
        paddingTop: '5px',
    },
    verificationBody: {
        fontSize: '18px',
        paddingTop: '5px',
    },
    verificationSubBody: {
        fontFamily: 'lato-light',
        fontSize: '17px'
    },
    verificationQuestion: {
        fontFamily: 'lato-light',
    },
}));


function FinancialVerification(props) {
    const classes = useStyle();

    const {setActiveStep, toggleFinancialVerificationModal, setVerificationState, dismiss} = props;

    const [showVerification, setShowVerification] = useState(0);

    const [showMessage, setShowMessage] = useState(0);

    const [verificationCompleted, setVerificationCompleted] = useState(0);

    const nextStep = () => {
        setShowVerification(1);
    };

    const dismissVerification = () => {
        setShowVerification(-1);
        sendNoConsentNotification();
    }

    const showConfirm = () => {
        setShowVerification(2);
    }

    const continueToCompletion = () => {
        toggleFinancialVerificationModal()
        dismiss()
    }

    return (
        <div className="FinancialVerification text-center">
            <div className="position-relative d-inline-block">
                <img src={VerifyIcon} title="Profile Verification" alt="Profile Verification" className="who-we-represent-img" />
            </div>

            {!verificationCompleted && <div className={classes.verificationTitle}>
                Welcome to financial verification
            </div>}

            {showMessage === 1 && <p>This may take a few minutes. Do not click refresh</p>}
            {!showMessage && showVerification === 1 && <>
                <p className="mb-0 mt-3 p--small">Select the account from where your down payment on the business will come from.</p>
                <p>For most people, this is will be your investment account</p>
            </>}

            {!showVerification && <Fragment>
                <p className="mt-3">Attract more sellers by becoming a credible buyer. Sellers are more likely to connect with a buyer that has been financially verified. Village Wellth securely and privately verifies your finances.</p>
                <p>Get your verification badge!</p>
                <p className="mt-2 p--small">
                    By selecting "Continue" you consent and agree to the <br/>
                    <a href="https://www.freeprivacypolicy.com/live/f7f00906-df08-473a-b965-00cd3a7cb1dc" target="_blank" className="text-underline">VillageWellth User Privacy Policy</a>
                </p>

                <div className="d-flex justify-content-center mt-5 mb-4">
                    <Button onClick={dismiss} className="h-auto p-1 border border-secondary u-color-secondary">
                        I'll do it later
                    </Button>

                    <Button onClick={nextStep} className={`${NextButton}`} >
                        Continue
                    </Button>
                </div>
            </Fragment>}

            {showVerification === -1 && <Fragment>
                <p class="mt-3">You can restart the verification process at any time</p>
                <p class="mt-2 p--small">
                    Becoming a verified buyer adds more credibility to your profile and will help attracting more sellers and will instantly increase your WellthScore!
                </p>
                <Button onClick={toggleFinancialVerificationModal} className="h-auto p-1 border border-secondary u-color-secondary my-4">
                    Ok
                </Button>
            </Fragment>}

            {
                verificationCompleted ? <>
                    <p className={classes.verificationTitle}>Thank you.</p>
                    <p className="mb-0">Your account is being verified and could take up to 1 hour.</p>
                    <p className="mb-0">You’ll receive an email once your profile has been updated.</p>
                </>
                :
                showVerification >= 1 && <Flinks showConfirm={showConfirm} setActiveStep={setActiveStep} setVerificationState={setVerificationState} setShowMessage={setShowMessage} setVerificationCompleted={setVerificationCompleted} />
            }

            {showVerification === 2 && <Button onClick={continueToCompletion} className="h-auto p-1 border border-secondary u-color-secondary my-4">
                Close
            </Button>}
        </div>
    )
}

export default FinancialVerification;
