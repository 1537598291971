import API from './ApiBase';

export const sendLoginId = async (loginId) => {
  return await API.post('/api/v1/buyers/loginId/',
    {
      'login_id': loginId
    });
};

export const sendNoConsentNotification = async () => {
  return await API.get('/api/v1/buyers/noConsent/');
};

export const getConfigs = async () => {
  return await API.get('/api/v1/buyers/configs/');
};