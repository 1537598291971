import API from './ApiBase';

// export const getCountries = async (id) => {
//     return await API.get(`/api/v1/config/countries/`);
// };

export const getIndustryTypes = async () => {
    return await API.get(`/api/v1/config/industries/`);
};

export const getCategoryTypes = async () => {
    return await API.get(`/api/v1/config/categories/`);
};

export const getCreditScoreOptions = async () => {
    return await API.get(`/api/v1/lookups/credit-score/`);
};

export const uploadMarketplaceFile = async (payload) => {
    return await API.post(`/api/v1/listing/upload/`, payload);
};

export const getLookupCountries = async () => {
    try {
        const countries = await API.get(`/api/v1/lookups/countries/`);
        countries.data = filterCountries(countries.data);
        return countries
    } catch (e) {
        return [];
    }
};

export const getHowYouHear = async () => {
    // try {
    //     return await API.get(`/api/v1/how-you-hear/`);
    // } catch (e) {
        return {
          data : [
            { id: 'SE', name: 'Search engine (Google, Yahoo, etc.)'},
            { id: 'FR', name: 'Recommended by friend or colleague'},
            { id: 'SM', name: 'Social media'},
            { id: 'BLG', name: 'Blog or publication'},
            { id: 'OTHR', name: 'Other'}
          ]
        };
    // }
};

export const filterCountries = (countries) => {
    return countries.filter((el) => {
        return (el.code === 'US' || el.code === 'CA');
    });
};

export const getLookupProvinces = async (countryId) => {
    return await API.get(`/api/v1/lookups/countries/${countryId}/provinces/`);
};

export const getLookupCitiesByProvince = async (provinceId) => {
    return await API.get(`/api/v1/lookups/provinces/${provinceId}/cities/`);
};

export const getLookupCities = async () => {
    return await API.get(`/api/v1/lookups/advisors/cities/`);
};

export const getLookupRadius = async () => {
    return await API.get(`/api/v1/lookups/radius/`);
};

export const getBuyingPrice = async (countryId) => {
    return await API.get(`/api/v1/lookups/buying-price/`);
};


// /api/v1/lookups/provinces/<province_id>/cities/
export const geoLocate = async () => {
    return new Promise((resolve, reject) => {
        let latitude = 0
        let longitude = 0
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    latitude = position.coords.latitude
                    longitude = position.coords.longitude
                    resolve({latitude, longitude})
                },
                (error) => {
                    const errors = {
                        1: 'Permission denied',
                        2: 'Position unavailable',
                        3: 'Request timeout'
                    }
                    // e.preventDefault()
                    reject(errors[error.code])
                },
            )
        }
    })
}
// const location = await geoLocate(e)
// location.long, location.lat
// openSearchModal()
