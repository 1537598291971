import React from "react"
import { FormControl, MenuItem, Select } from "@material-ui/core"

const SingleSelect = ({
  children, disabled, placeholder, selectedValue, setSelectedValue
}) => {
  return (
    <FormControl>
      <Select
        displayEmpty
        disabled={disabled}
        variant='outlined'
        inputProps={{ 'aria-label': 'location select' }}
        value={selectedValue}
        onChange={(e) => setSelectedValue(e.target.value)}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {children}
      </Select>
    </FormControl>

  )
}

export default SingleSelect
