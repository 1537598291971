export const local = {
  apiUrl: "http://localhost:3000/",
  // apiUrl: "http://vw-staging-nim.us-east-2.elasticbeanstalk.com/api/v1/",
  webUrl: "localhost",
};

export const dev = {
  // https://dev2.api.villagewellth.com/
  apiUrl: "https://dev-api.villagewellth.com/",
  webUrl: "dev.villagewellth.com",
};

export const staging = {
  // https://dev2.api.villagewellth.com/
  apiUrl: "https://alpaca.villagewellthstaging.staging.c66.me/",
  webUrl: "vw.nim-client.work",
};

export const prod = {
  apiUrl: "https://api-new.villagewellth.com/",
  webUrl: "villagewellth.com",
};

const getConfig = function () {
  if (process.env.API_URL) {
    return process.env.API_URL;
  }

  if (window.location.hostname.indexOf(local.webUrl) > -1) {
    return local;
  }

  if (window.location.hostname.indexOf(dev.webUrl) > -1) {
    return dev;
  }

  if (window.location.hostname.indexOf(staging.webUrl) > -1) {
    return staging;
  }

  if (window.location.hostname.indexOf(prod.webUrl) > -1) {
    return prod;
  }

  return prod;
};

export { getConfig as default };
