import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import AuthHelper from "shared/helpers/AuthHelper";
import Header from "components/Header";
import Footer from "components/Footer";
import SideBar from "components/SideBar";
import { getProfileInfo } from "../../shared/apis/UserService";
import { withRouter } from "react-router-dom";
import MenuDrawer from "../../components/MenuDrawer";
import { Menu } from "@material-ui/core";

const DefaultLayout = ({
  component: Component,
  isExternal,
  enableRedirect,
  ...rest
}) => {
  const authHelper = new AuthHelper();
  let isLoggedIn = authHelper.isLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      checkIfAllDataExist();
    }
  }, []);

  const checkIfAllDataExist = async () => {
    try {
      const data = await getProfileInfo();
      const profile = data.profile;
      //check is necessary to show onboarding
      if (profile.role === "seller" || profile.role === "Sr") {
      } else if (
        !data.first_name ||
        !data.last_name ||
        !profile.industries_details.length ||
        !profile.user_industries.length ||
        !profile.expertise ||
        !profile.buyer_type ||
        !profile.total_assets_available ||
        !profile.target_company_earnings ||
        !profile.country ||
        !profile.bio ||
        !profile.desired_business_country
      ) {
        rest.history.push("/dashboard");
      }
    } catch (e) {}
  };

  let userRole = authHelper.getRole();
  return (
    <Route
      {...rest}
      render={matchProps => {
        if (enableRedirect && isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: matchProps.location },
              }}
            />
          );
        }
        return (
          <div className="c-default-layout">
            <MenuDrawer />
            <Header showIcons={false} isExternal={isExternal} />
            {/* {isLoggedIn && !isExternal &&
                        <SideBar userRole={userRole}></SideBar>
                    } */}
            <main
              className={`c-layout__main c-default-layout__main container-fluid ${
                isLoggedIn && !isExternal && "c-show-side-bar"
              }`}
            >
              <Component {...matchProps} />
            </main>
            <Footer />
          </div>
        );
      }}
    />
  );
};

export default withRouter(DefaultLayout);
