import React from 'react';
import Button from "@material-ui/core/Button";
import {ReactComponent as Verify} from 'assets/images/verify.svg';
// import withModals from "../withModals";
// import { WELCOME_MODAL } from "shared/modals/constants";

function SuccessMsg({ toggleSuccessModal, message }) {
    const toggleSuccess = () => {
        toggleSuccessModal();
    }
    return (
        <div className="text-center">
            <Verify alt="Verify"/>
            <h1 className="h2 mb-3">Success!</h1>
            <p className="mt-8">
                {message}
            </p>
            <Button onClick={toggleSuccess} className="mt-4" variant="contained" color="primary">
            Go to dashboard
            </Button>
        </div>
    )
}

export default SuccessMsg;
