import React, { useRef, useState } from 'react';
import { useMount } from 'ahooks';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash'

import { Button, InputAdornment, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import './index.scss'
import {
  BackButton,
  BreadcrumbsClass, ButtonContainer, CenterNextButton, EmptyDiv,
  FormContainerClass, LabelClass, NextButton,
  OnboardingStepContainerClass,
  TitleClass
} from "../shared/BootStrapStyling";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import RadioButton from '../StepThree/RadioButton';
import { ADDITIONAL_INVESTORS } from "../../../shared/constants"
import { ErrorMessage } from "@hookform/error-message"

const StepSix = ({ onSubmit, defaultValue, onBack }) => {
  const { clearErrors, control, errors, handleSubmit, setValue } = useForm({ mode: 'onBlur' })

  const [additionalInvestorsValue , setAdditionalInvestorsValue] = useState(ADDITIONAL_INVESTORS[0].value)

  const IsSubmitted = useRef(false);


  useMount(() => {
    setAdditionalInvestorsValue(defaultValue?.purchase_with_others ?? null)
    setValue('totalAssetsAvailable', defaultValue?.total_assets_available ?? null)
    setValue('requireFinancing', defaultValue?.engaged_with_lending_institution ?? null)
  });

  const onSubmitStepSix = (data) => {
    const values = {
      ...defaultValue,
      total_assets_available: data.totalAssetsAvailable,
      purchase_with_others: additionalInvestorsValue,
      engaged_with_lending_institution: data.requireFinancing,
    }
    if (IsSubmitted.current && (data.totalAssetsAvailable || data.totalAssetsAvailable === 0)) {
      onSubmit(values);
    } else if (data.totalAssetsAvailable  || data.totalAssetsAvailable === 0) {
      onBack(values);
    } else {
      setValue('totalAssetsAvailable', null)
    }
  };

  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={3} /></div>

      <form
      id="stepSix"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepSix)}
        className={`${FormContainerClass}`}
      >
        <h1 className={`${TitleClass}`}>Let's talk numbers.</h1>
        <label  className={`${LabelClass}`}>What is your budget for a deposit/ cash portion toward this acquisition?</label>
        <small  className={`pb-3 font-light`}>This is the amount we will authenticate upon connecting your banking information in stage 2.</small>
        <Controller
          name="totalAssetsAvailable"
          control={control}
          rules={{
            required: "Field required",
            pattern: { value: /^[0-9]+$/, message: 'Only numbers are accepted' },
            min: { value: 1, message: 'Please enter a number greater than zero' }
          }}
          render={(props) => (
            <TextField
              {...props} id="from-field" variant='outlined' className='w-100'
              placeholder='e.g. 500000'
              onChange={(e) => {
                clearErrors('totalAssetsAvailable')
                if (Number.isInteger(Number(e.target.value)) && !isEmpty(e.target.value)) {
                  props.onChange(Number(e.target.value))
                } else if (isEmpty(e.target.value)) {
                  props.onChange(null)
                } else {
                  props.onChange(0)
                }
              }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            />
          )}
        />
        <ErrorMessage className="error-message mt-1" errors={errors} name="totalAssetsAvailable" as="p" />
        <label className={`${LabelClass} pt-3`}>Do you have access to additional investor capital?</label>
        <div className='radio-section' onChange={e => setAdditionalInvestorsValue(JSON.parse(e.target.value))}>
          <RadioButton value={true} label='Yes' state={additionalInvestorsValue} group='additional-capital' />
          <RadioButton value={false} label='No' state={additionalInvestorsValue} group='additional-capital' />
        </div>

        <label className={`${LabelClass}`}>Will you require financing?</label>
        <div className='radio-section'>
          <Controller
            name="requireFinancing"
            control={control}
            defaultValue={null}
            rules={{ required: "Select required"}}
            render={(props) => (
              <>
                <RadioButton
                  {...props} value="Yes" label='Yes' valueType='text'
                  state={props.value} group='require-financing'
                />
                <RadioButton
                  {...props} value="No" label='No' valueType='text'
                  state={props.value} group='require-financing'
                />
              </>
            )}
          />
        </div>
        <ErrorMessage className="error-message mt-1" errors={errors} name="requireFinancing" as="p" />

        <div className={`${ButtonContainer}`}>
          <div>
            <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                    className={`${BackButton}`}>
              {'Previous'}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button type='submit' onClick={onClickSubmit}  className={`${NextButton}`}>
              {'Next'}
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
        </div>
      </form>
    </div>
  );
};

StepSix.defaultProps = {};

StepSix.propTypes = {};

export default StepSix;
