import React, { useRef, useState } from 'react';
import { useMount } from 'ahooks';
import { Button, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { Controller, useForm } from "react-hook-form";

import './index.scss';
import { get } from 'lodash';
import { ErrorMessage } from '@hookform/error-message';
import {
  BackButton, CenterNextButton,
  FormContainerClass,
  LabelClass,
  OnboardingStepContainerClass,
  TitleClass,
  ButtonContainer, EmptyDiv
} from "../shared/BootStrapStyling";


const StepSeven = (props) => {
    const { onComplete, defaultValue, onBack } = props;

    const { clearErrors, control, errors, handleSubmit, setValue } = useForm({ mode: 'onSubmit' });

    const IsSubmitted = useRef(false);

    const [IsWithBrokerage, setIsWithBrokerage] = useState(false)

    useMount(() => {
        setIsWithBrokerage((get(defaultValue, 'is_with_brokerage') || get(defaultValue, 'is_with_brokerage') === false) && get(defaultValue, 'is_with_brokerage', '').toString())
      setValue('bio', defaultValue?.bio ?? '')
    });


    const onSubmitStepSeven = (data) => {
        const values = {
            ...defaultValue,
            is_with_brokerage: IsWithBrokerage,
            bio: data.bio
        }
        if (IsSubmitted.current) {
          console.log('this is values', JSON.stringify(values))
            onComplete(values);
        } else {
            onBack(values);
        }
    };
    const onClickBack = () => {
        IsSubmitted.current = false;
    };

    const onClickSubmit = () => {
        IsSubmitted.current = true;
    };
    return (
      <div className={`${OnboardingStepContainerClass}`}>
        <form
          id="stepSeven"
          className={`${FormContainerClass}`}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmitStepSeven)}
        >
          <h1 className={`${TitleClass}`}>Last but not least…</h1>
          <label className={`${LabelClass}`}>Explain your motivation for acquiring a business</label>
          <p className='pb-3'>Sellers want to know what is driving your decision to acquire a
            business. Set yourself <br/> apart from other buyers by articulating your personal story.</p>
          <Controller
            name="bio"
            control={control}
            defaultValue={''}
            rules={{
              required: "Field required",
              maxLength: { value: 1000, message: 'Field exceeds 1000 character limit' }
            }}
            render={(props) => (
              <TextField
                {...props} id="bio" variant='outlined' multiline rows={5}
                className='form-input w-100' placeholder='Write between 3 - 4 high impact sentences…'
                onChange={(e) => {
                  clearErrors('bio')
                  props.onChange(e.target.value)
                }}
              />
            )}
          />
          <ErrorMessage className="error-message mt-1" errors={errors} name="bio" as="p" />
          <div className={`${ButtonContainer}`}>
            <div>
              <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                      className={`${BackButton}`}>
                {'Previous'}
              </Button>
            </div>
            <div className={`${CenterNextButton}`}>
              <Button type='submit' onClick={onClickSubmit}  className={`h-auto py-2 px-lg-5 nextBtn w-100`}>
                {'Finish Profile'}
              </Button>
            </div>
            <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
          </div>
        </form>
      </div>
    );
};

StepSeven.defaultProps = {
};

StepSeven.propTypes = {
};

export default StepSeven;
